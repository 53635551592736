<template>
  <div v-if="Lb_IsLoaded" class="app-content-wrapper apps-wrapper --filemanager shadow-lg">
    <div
      :class="{open: isBlock}"
      class="app-overlay"
      @click="overlayApp"
    />
      <div
        :class="{open: isOpen}"
        class="app-sidebar"
      >
      <div class="app-sidebar-header white py-5">
        <v-btn
          class="py-5"
          text
          block
          color="white"
        >
          <v-icon left>
            mdi-file-plus
          </v-icon> {{$t('general.add_file')}}
        </v-btn>
      </div>
      <vue-perfect-scrollbar
        :settings="{suppressScrollX: true, wheelPropagation: false}"
        class="h-100 rtl-ps-none ps scroll"
        style="height: 100%"
      >
        <div class="app-sidebar-body mt-6">
          <v-list flat>
            <v-subheader
              class="text--disabled font-weight-medium text-uppercase"
            >
              {{$t('general.my_doucuments')}}
            </v-subheader>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in La_DocumentCategories"
                :key="i"
                class="px-10"
              >
                <v-list-item-icon class="mr-3">
                  <v-icon v-text="'mdi-folder-outline'" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="font-weight-medium"
                    v-text="item.s_Description"
                    @click="getCategorieDocument(item.i_SSE_InformationTypeID)"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </vue-perfect-scrollbar>
    </div>
    <div class="app-content relative">
      <div class="app-header white">
        <div
          class="d-flex justify-space-between flex-wrap align-baseline"
        >
          <div class="">
            <!-- app-icon class -->
            <div
              class="nested-sidebar-toggle d-md-none d-sm-block"
              @click="appToggle"
            >
              <v-icon color="dark">
                mdi-menu-open
              </v-icon>
            </div>
            <v-text-field
              hide-details="auto"
              class="sm:hidden md:flex ma-0 pa-0"
              :label="$t('general.search')"
              outlined
              dense
              @input="searchDocument"
              prepend-inner-icon="mdi-magnify"
            />
          </div>
          <div>
            <v-btn
              icon
              color="primary"
            >
              <v-icon>mdi-account-check</v-icon>
            </v-btn>
            <v-btn
              icon
              color="primary"
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
            <v-btn
              icon
              color="primary"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <vue-perfect-scrollbar
        :settings="{suppressScrollX: true, wheelPropagation: false}"
        class="h-100 rtl-ps-none ps scroll"
        style="height: 100%"
      >
        <div class="app-body mt-10 px-8">
          <v-row>
            <v-col
              cols="12"
              class=""
            >
              <!--<p
                class="font-weight-medium ma-0 text--disabled text-12 text-uppercase"
              >
                {{$t('general.folder')}}
              </p> -->
            </v-col>
          </v-row>
          <!-- <v-container class="grid filemanager-content">
            <v-row>
              <v-col
                md="6"
                lg="4"
                class=""
              >
                <base-card>
                  <v-card-text class="bg-white">
                    <div class="d-flex align-center">
                      <v-icon class="mr-2">
                        mdi-folder-outline
                      </v-icon>
                      <div>
                        <p
                          class="font-weight-medium ma-0 "
                        >
                          {{$t('general.project')}}
                        </p>
                        <p
                          class="text--disabled ma-0 text-body-2"
                        >
                          2 {{$t('general.files')}}, 3.05mb
                        </p>
                      </div>
                    </div>
                  </v-card-text>
                </base-card>
              </v-col>
              <v-col
                md="6"
                lg="4"
                class=""
              >
                <base-card>
                  <v-card-text class="bg-white">
                    <div class="d-flex align-center">
                      <v-icon class="mr-2">
                        mdi-folder-outline
                      </v-icon>
                      <div>
                        <p
                          class="font-weight-medium ma-0 "
                        >
                          {{$t('general.music')}}
                        </p>
                        <p
                          class="text--disabled ma-0 text-body-2"
                        >
                          2 {{$t('general.files')}}, 3.05mb
                        </p>
                      </div>
                    </div>
                  </v-card-text>
                </base-card>
              </v-col>
              <v-col
                md="6"
                lg="4"
                class=""
              >
                <base-card>
                  <v-card-text class="bg-white">
                    <div class="d-flex align-center">
                      <v-icon class="mr-2">
                        mdi-folder-outline
                      </v-icon>
                      <div>
                        <p
                          class="font-weight-medium ma-0 "
                        >
                          {{$t('general.photo')}}
                        </p>
                        <p
                          class="text--disabled ma-0 text-body-2"
                        >
                          2 {{$t('general.files')}}, 3.05mb
                        </p>
                      </div>
                    </div>
                  </v-card-text>
                </base-card>
              </v-col>
            </v-row>
          </v-container> -->
          <v-container class="">
            <v-row v-if="La_DocumentsFromAPI">
              <!-- <div class="grid filemanager-content"> -->
              <v-col v-for="(item, i) in La_Documents"
                :key="i"
                md="6"
                lg="4"
                class=""
              >
                <base-card>
                  <a @click="getDocument(item.i_SSE_DatasetDocumentID)">
                    <div class="py-6">
                      <v-img
                        width="40"
                        class=" h-auto ma-auto"
                        src="@/assets/images/pdf-logo.jpg"
                      />
                    </div>
                    <v-card-text class="bg-white">
                      <p
                        class="card-text font-weight-bold ma-0 "
                      >
                        {{getFilename(item.s_Filename)}}
                      </p>
                      <p
                        class="text--disabled font-weight-medium ma-0 "
                      >
                        {{formatBytes(item.i_FileSize)}}
                      </p>
                    </v-card-text>
                  </a>
                </base-card>
              </v-col>
              <!-- </div> -->
            </v-row>
          </v-container>
        </div>
      </vue-perfect-scrollbar>
    </div>
    <!-- <v-dialog> -->
    <v-dialog
      v-model="Lb_Dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{$t('general.please_wait')}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- </v-dialog> -->
  </div>
</template>
<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import store from '@/store'
  import { get, getFile } from '../../../worker/worker-api'
  import { mapGetters } from 'vuex'
  export default {
    name: 'Filemanager',
    metaInfo () {
      return{
        // title will be injected into parent titleTemplate
        title: this.$t('general.my_doucuments'),
      }
    },
    props: {
      // AdressID from Child
      childId: {
        type: Number,
        default: 0
      }
    },    
    components: {
      VuePerfectScrollbar,
    },
    data: () => ({
      isOpen: false,
      isBlock: false,
      // item: 1,
      // itemsTwo: 0,
      La_DocumentCategories: [],
      La_DocumentsFromAPI:[],
      La_Documents: [],
      Lb_IsLoaded:false,
      La_SearchedDocuments:[],
      Lb_Dialog: false,
      i_AddressRoleID: null,
    }),
    computed: {
      ...mapGetters(['getPlanningPeriodID', 'getImpersonation']),
    },
    methods: {
      searchDocument(As_SearchKeyWord){
        if(As_SearchKeyWord != ''){
          const result = this.La_DocumentsFromAPI.filter(Aa_DocumentssFromAPI => Aa_DocumentssFromAPI.s_Filename.toLowerCase().includes(As_SearchKeyWord.toLowerCase()));
          this.La_Documents = result;
        }//if 
        else{
          this.La_Documents = this.La_DocumentsFromAPI;
        }//else
      },//searchDocument
      getCategorieDocument(Ai_DocumentCategorieID){
        const result = this.La_DocumentsFromAPI.filter(Aa_DocumentssFromAPI => Aa_DocumentssFromAPI.i_SSE_InformationTypeID === Ai_DocumentCategorieID);
        this.La_Documents = result;
      },//getCategorieDocument
      getDocument(Ai_DocumenID){
        this.Lb_Dialog = true;
        getFile(`${process.env.VUE_APP_SMT_API_URL}/student/document/${Ai_DocumenID}`,{})
        .then(response => {
          let blob = new Blob([response],  {type: 'application/pdf'});
          let  url = URL.createObjectURL(blob);
          window.open(url);
          this.Lb_Dialog = false;
        }).catch(error => {
          console.log(error)
          this.Lb_Dialog = false;
        });
      },//getDocument
      getDocuments(Ai_User, Ai_PlanningPeriod, Ai_AddressRoleID){
        if (Ai_AddressRoleID === null) return
        store.dispatch('changeThemeLoadingState', true);
        this.Lb_IsLoaded =false;
        get(`${process.env.VUE_APP_SMT_API_URL}/student/${Ai_User}/documents/planning-period/${Ai_PlanningPeriod}`, {
          i_AddressRoleID: Ai_AddressRoleID
        })
        .then(response => {
          this.La_DocumentsFromAPI = response.ArrayData;
          this.La_Documents = response.ArrayData.sort((a, b) => a.s_Filename > b.s_Filename ? 1 : -1) ;
          this.La_DocumentCategories = response.InformationTypesData;
          this.Lb_IsLoaded = true;
          store.dispatch('changeThemeLoadingState', false);
        }).catch(error => {
          this.Lb_IsLoaded = true;
          store.dispatch('changeThemeLoadingState', false);
          console.log(error);
        });
      },//getDocuments
      appToggle: function () {
        this.isOpen = !this.isOpen
        this.isBlock = !this.isBlock
      },//appToggle

      overlayApp: function () {
        this.isOpen = !this.isOpen
        this.isBlock = !this.isBlock
      },//overlayApp

      getFilename(a){
        return a.replace(/^.*[\\\/]/, '');
      },//getFilename

      formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      }//formatBytes
    },
    beforeMount () {
      if (this.getImpersonation.b_Status) this.i_AddressRoleID = this.getImpersonation.i_AddressRoleID
      else this.i_AddressRoleID = this.$smt.getAddressRoleID(this.$route.meta.role)
    },
    mounted () {
      if (this.childId != 0){
      this.getDocuments(this.childId, store.getters.getPlanningPeriodID, this.i_AddressRoleID);
      }// if
      else{      
      this.getDocuments(store.getters.getUserID, store.getters.getPlanningPeriodID, this.i_AddressRoleID);
      }
    },//mounted
  }
</script>
<style lang="scss">


.app-content-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    height: calc(100vh - 200px);

    .app-overlay {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        display: none;
        z-index: 2;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            &.open {
                display: block;
                transition: all 0.3s ease-in;
            }
        }
    }
    .app-sidebar {
        width: 280px;
        position: relative;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            position: absolute;
            left: -340px;
            z-index: 5;
            height: calc(100vh - 120px);
            transition: all 0.3s ease-in;
            &.open {
                left: 0;
            }
        }
        .app-sidebar-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-sidebar-body {
            // overflow-y: scroll;
            // height: 100%;
            padding-top: 3.375rem;
            width: 280px;
        }
    }

    .app-content {
        width: calc(100% - 280px);
        @media only screen and (max-width: 959px) {
            width: 100%;
        }
        // margin-left: 30px;
        .app-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-body {
            flex: 1 1 auto;
            padding-top: 3.375rem;
            // overflow-y: scroll;
            height: calc(100% - 130px);
        }
        .app-footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 130px;
        }
    }
}
.app-icon {
    display: none;
    @media only screen and (max-width: 959px) {
        display: block;
    }
}
.eg-filemanager {
    background-color: #fff;
}
.filemanager-content {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
}

.apps-wrapper {
    &.--filemanager {
        .nested-sidebar-toggle {
            @media (min-width: 959px) {
                display: none;
            }
        }
    }
}
</style>
